<template>
  <div class="branch-detail">
    <div class="card">
      <div class="content p-4">
        <fd-form-section title="Branch Details">
          <div class="agency col-12 p-3 mb-2">
            <p>Agency</p>
            <p class="data d-inline-block">{{ detail.agency.name }}</p>
            <div
              v-if="detail.isHeadquarter"
              class="headquarter-sticker d-inline-block"
            >
              Headquarter
            </div>
          </div>
          <div :class="detailCSS">
            <p>Branch Name</p>
            <p class="data">{{ detail.name }} ({{ detail.branchCode }})</p>
          </div>
          <div :class="detailCSS">
            <p>Register Estate Agent Name</p>
            <p class="data">{{ detail.registerEstateAgentName }}</p>
          </div>
          <div :class="detailCSS">
            <p>Board Reg. No.</p>
            <p class="data">{{ detail.businessRegisterNumber }}</p>
          </div>
          <div :class="detailCSS">
            <p>SSM No.</p>
            <p class="data">{{ detail.ssmNumber }}</p>
          </div>
          <div :class="detailCSS">
            <p>SST No.</p>
            <p class="data">{{ detail.sstNumber }}</p>
          </div>
          <div :class="detailCSS">
            <p>Ejen Hartanah No.</p>
            <p class="data">{{ detail.ejenHartanahNumber }}</p>
          </div>
          <div class="col-12 px-1 mb-2">
            <p>Description</p>
            <p class="data">{{ detail.description }}</p>
          </div>
        </fd-form-section>
        <fd-form-section title="Contact Details">
          <div :class="detailCSS">
            <p>Email</p>
            <p class="data">{{ detail.email }}</p>
          </div>
          <div :class="detailCSS">
            <p>Contact</p>
            <p class="data">{{ detail.contact }}</p>
          </div>
          <div :class="detailCSS">
            <p>Fax</p>
            <p class="data">{{ detail.fax }}</p>
          </div>
          <div :class="detailCSS">
            <p>Tenancy Agreement Person In Charge Email</p>
            <p class="data">{{ detail.tenancyAgreementPersonInChargeEmail }}</p>
          </div>
        </fd-form-section>
        <fd-form-section title="Address Details">
          <div class="col-12 px-1 mb-2">
            <p>Address</p>
            <p class="data">{{ detail.address }}</p>
          </div>
          <div :class="detailCSS">
            <p>Area</p>
            <p class="data">{{ detail.area.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>City</p>
            <p class="data">{{ detail.city.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>State</p>
            <p class="data">{{ detail.state.name }}</p>
          </div>
          <div :class="detailCSS">
            <p>Country</p>
            <p class="data">{{ detail.country.name }}</p>
          </div>
        </fd-form-section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      detailCSS: "col-12 sm-col-6 md-col-3 mb-2 px-1"
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    console.log(this.detail);
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.content {
  max-height: 60vh;
  overflow-y: auto;
  .label {
    font-size: 14px;
  }
  .data {
    font-size: 16px;
  }
  .agency {
    box-shadow: 0px 2px 12px -4px #00000015;
    .headquarter-sticker {
      background: $color-accent;
      color: white;
      border-radius: 4px;
      padding: 4px 12px;
      margin: 0 10px;
    }
  }
}
</style>
